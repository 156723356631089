<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div v-if="submerchant.payoutsWithheld" class="alert alert-danger alert-with-icon">
          <span data-notify="icon" class="fa fa-exclamation-triangle"></span>
          <span data-notify="message">
            <h6 class="mt-0">
              <strong>Payouts paused</strong>
              <span v-if="submerchant.payoutsWithheldReason">: {{ submerchant.payoutsWithheldReason | humanize }}</span>
            </h6>

            <p>
              We cannot make payouts to your account until this is resolved. You
              can continue to accept payments as normal.
            </p>

            <p v-if="submerchant.payoutsWithheldMessage" v-html="$options.filters.urls(
              submerchant.payoutsWithheldMessage,
              'domain',
              'alert-link'
            )
              "></p>

            <a v-if="submerchant.payoutsWithheldUrl" class="btn btn-default btn-lg"
              :href="submerchant.payoutsWithheldUrl">
              Please click here <i class="fa fa-arrow-right ms-2"></i>
            </a>

            <div class="text-muted small">
              Need help?
              <a class="alert-link" href="https://cabcard.services/contact">Please contact support.</a>
            </div>
          </span>
        </div>

        <div class="alert alert-warning alert-with-icon alert-dismissible" v-for="incident in incidents"
          :key="incident.id">
          <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close">
            <i class="nc-icon nc-simple-remove"></i>
          </button>
          <span data-notify="icon" class="fa fa-exclamation-triangle"></span>
          <span data-notify="message">
            <h6 class="mt-0">
              <strong>{{ incident.type | humanize }} incident</strong>:
              {{ incident.title }}
              <small v-if="incident.starts_at">(started
                {{ incident.starts_at | moment("from", "now") }})</small>
            </h6>

            <a href="https://status.cabcard.services">Please visit our status page for full details.</a>

            <!-- link to the  /scan-to-pay route -->
            <br />
            <router-link to="/scan-to-pay" class="alert-link">
              <i class="fa fa-arrow-right ms-2"></i> Download emergency kit here
            </router-link>
          </span>
        </div>
      </div>
    </div>

    <div v-if="withheldSalesTotalCount > 0" class="alert alert-danger alert-with-icon">
      <span data-notify="icon" class="fa fa-exclamation-triangle"></span>
      <span data-notify="message">
        <h6 class="mt-0">
          <strong>{{ withheldSalesTotalCount | pluralize('Sale') }} Withheld</strong>
        </h6>
        <p>
          {{ withheldSalesTotalCount }} {{ withheldSalesTotalCount | pluralize('sale') }} withheld from your account.
          This may affect your payouts. You can continue to accept payments as normal.
        </p>

        <button type="button" class="btn btn-default btn-sm" @click="showWithheldSalesModal()">
          View details <i class="fa fa-arrow-right ms-2"></i>
        </button>

        <div class="text-muted small mt-3">
          Need help?
          <a class="alert-link" href="https://cabcard.services/contact">Please contact support.</a>
        </div>
      </span>
    </div>

    <!-- Modal for displaying withheld disbursements -->
    <div v-if="showWithheldDisbursementsModal" class="modal-wrapper">
      <!-- Modal backdrop when modal is open -->
      <div class="modal-backdrop fade show" @click="showWithheldDisbursementsModal = false"></div>

      <!-- Modal dialog -->
      <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header border-bottom">
              <h5 class="modal-title">
                <i class="fa fa-exclamation-triangle text-warning mr-2"></i>
                {{ withheldSalesTotalCount | pluralize('Withheld Sale') }}
              </h5>
              <button type="button" class="close" @click="showWithheldDisbursementsModal = false" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="max-height: 60vh; overflow-y: auto;">
              <div>
                <h4 class="mt-0">{{ withheldSalesTotalCount }} withheld {{ withheldSalesTotalCount | pluralize('sale')
                }}
                  <span class="badge badge-warning text-dark ml-2">Action required</span>
                </h4>
              </div>
              <div v-for="(sale, index) in withheldSales" :key="sale.id"
                class="mt-3 p-3 border border-warning rounded bg-light" :class="{ 'border-top': index > 0 }">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-start">
                  <div>
                    <h6>
                      <a :href="'/transactions/sales/' + sale.id" class="text-dark font-weight-bold mb-0"
                        target="_blank" style="text-transform: lowercase !important;">
                        {{ sale.id }}<i class="fa fa-external-link ml-2"></i>
                      </a>
                    </h6>
                    <h6>
                      <small v-if="sale.capturedAt">{{ sale.capturedAt | moment("DD/MM/YYYY HH:mm") }}</small>
                    </h6>
                  </div>
                  <div v-if="sale.amount" class="text-md-right mt-2 mt-md-0">
                    <h5>
                      {{
                        sale.amountFormatted
                      }}
                    </h5>
                  </div>
                </div>

                <div v-if="sale.disbursementWithheldReason" class="my-3">
                  <strong class="text-danger">Reason:</strong>
                  <div class="pl-2 border-left border-secondary">
                    <template>
                      {{ sale.disbursementWithheldReason | humanize }}
                    </template>
                  </div>
                </div>

                <div class="mb-3">
                  <template v-if="sale.disbursementWithheldMessage">
                    <div>{{ sale.disbursementWithheldMessage }}</div>
                  </template>
                </div>

                <div class="mt-4">
                  <a v-if="sale.disbursementWithheldUrl" class="btn btn-secondary btn-sm mt-0 px-4 py-2"
                    :href="sale.disbursementWithheldUrl">
                    Resolve this issue <i class="fa fa-arrow-right ml-2"></i>
                  </a>
                  <span v-else>
                    <a href="https://cabcard.services/contact" class="btn btn-secondary btn-sm mt-0 px-4 py-2"
                      target="_blank">
                      <span class="d-none d-sm-inline">Contact support for assistance</span>
                      <span class="d-inline d-sm-none">Get help</span>
                      <i class="fa fa-phone ml-2"></i>
                    </a>
                  </span>
                </div>
              </div>

              <!-- Pagination -->
              <div v-if="withheldSalesTotalCount > withheldSales.length && !withheldSalesLoading"
                class="text-center mt-4">
                <button class="btn btn-info" @click="loadMoreWithheldSales">
                  Load More Sales
                  <i class="fa fa-arrow-down ml-2"></i>
                </button>
                <div class="text-muted mt-2">
                  Showing {{ withheldSales.length }} of {{ withheldSalesTotalCount }} withheld sales
                </div>
              </div>
              <div class="modal-footer flex-column flex-sm-row">
                <div class="text-muted small mt-2 ml-2 mb-sm-0 mr-sm-auto">
                  Need help?
                  <a href="https://cabcard.services/contact">Please contact support.</a>
                </div>
                <button type="button" class="btn btn-secondary px-4 mx-4"
                  @click="showWithheldDisbursementsModal = false">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row" v-if="showSubmerchantStatistics">
      <div class="col-md-8 col-sm-12">
        <chart-card v-if="salesByDay7DaysChart.data" :chart-data="salesByDay7DaysChart.data"
          chart-title="Total daily earnings" :chart-options="{
            tooltips: { enabled: true },
            title: { display: true, text: 'Total daily earnings' },
            scales: {
              yAxes: [
                { scaleLabel: { display: true, labelString: 'Value (GBP)' } },
              ],
            },
          }">
          <span slot="title">{{ salesByDay7DaysChart.meta.summary }}</span>
          <badge slot="title-label" type="success">{{ salesByDay7DaysChart.meta.count }} sales</badge>

          <template slot="footer-title">
            <router-link to="/transactions/sales" class="text-success">
              View all earnings
              <span class="pull-right">
                <i class="fa fa-chevron-right"></i>
              </span>
            </router-link>
          </template>
        </chart-card>
      </div>

      <div class="col-md-4 col-sm-12">
        <card v-if="currentDaySales">
          <h3 name="title" class="mb-0">Today's Sales</h3>

          <span name="subTitle" class="text-muted">
            <time v-if="currentDaySales.from" :datetime="currentDaySales.from | moment()">{{ currentDaySales.from |
              moment("HH:mm") }}</time>
            to
            <time v-if="currentDaySales.to" :datetime="currentDaySales.to | moment()">{{ currentDaySales.to |
              moment("HH:mm") }}</time>
            <span v-if="ago">, checked
              <time :datetime="ago">{{
                ago | moment("from", "now")
              }}</time></span>
          </span>

          <div class="row mt-2">
            <div class="col-sm-4">
              <strong class="text-info">Total value:</strong>
              <br />
              <span style="font-size: 1.8em">{{
                currentDaySales.amount | money(currentDaySales.currency)
              }}</span>
            </div>

            <div class="col-sm-4">
              <strong class="text-info">Transactions:</strong>
              <br />
              <span style="font-size: 1.8em">{{ currentDaySales.count }}</span>
            </div>

            <div class="col-sm-4">
              <strong class="text-info">Average value:</strong>
              <br />
              <span style="font-size: 1.8em">{{
                currentDaySales.average | money(currentDaySales.currency)
              }}
              </span>
            </div>
          </div>
        </card>

        <card v-if="balance">
          <h3 name="title" class="mb-0">Account Balance</h3>

          <span name="subTitle" class="text-muted" v-if="ago">Checked {{ ago | moment("from", "now") }}</span>

          <div class="row mt-2">
            <div class="col-sm-6">
              <strong class="text-success">Next payout</strong>
              <ul class="list-unstyled" style="font-size: 1.8em">
                <li v-for="row in balance.available" v-bind:key="row.currency">
                  {{ row.amount | money(row.currency) }}
                </li>
              </ul>
            </div>
            <div class="col-sm-6 sr-only">
              <strong class="text-info">Not yet available</strong>
              <ul class="list-unstyled" style="font-size: 1.8em">
                <li v-for="row in balance.pending" v-bind:key="row.currency">
                  {{ row.amount | money(row.currency) }}
                </li>
              </ul>
            </div>
          </div>

          <p v-if="lastPayout">
            Your last payout was for
            {{ lastPayout.amount | money(lastPayout.currency) }} on
            <span v-if="lastPayout.createdAt">{{
              lastPayout.createdAt | moment("DD/MM/YYYY")
            }}</span>
          </p>

          <p class="text-muted">
            Transactions appear in your available balance after 1-2 working days, due to the bank clearance process.
          </p>

          <template slot="footer">
            <router-link to="/payouts" class="text-info">
              View your payout history
              <span class="pull-right">
                <i class="fa fa-chevron-right"></i>
              </span>
            </router-link>
          </template>
        </card>

        <card v-if="terminals.length">
          <h3 name="title">
            Your {{ terminals.length | pluralize("Terminal") }}
          </h3>

          <div class="list-group list-group-flush">
            <router-link :to="`/terminals/${terminal.id}`"
              class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
              v-for="terminal in terminals" v-bind:key="terminal.id">
              <span>
                Serial no.
                <code>{{ terminal.serialNumber }}</code>
              </span>

              <span v-if="
                terminal.reportedConfiguration &&
                terminal.reportedConfiguration.isUpToDate
              " class="badge badge-success badge-pill">
                <i class="fa fa-check"></i>&nbsp; Up to date
              </span>
              <span v-else-if="
                terminal.reportedConfiguration &&
                !terminal.reportedConfiguration.isUpToDate
              " class="badge badge-danger badge-pill">
                <i class="fa fa-exclamation-triangle"></i>&nbsp; Out of date
              </span>
              <span v-else class="badge badge-warning badge-pill">
                <i class="fa fa-question-circle"></i>&nbsp; Status unknown
              </span>
            </router-link>
          </div>

          <template slot="footer">
            <router-link to="/terminals" class="text-info">
              View your terminals
              <span class="pull-right">
                <i class="fa fa-chevron-right"></i>
              </span>
            </router-link>
          </template>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <sales-list></sales-list>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import _ from "lodash";

import ChartCard from "src/components/UIComponents/Cards/ChartCard";
import Badge from "src/components/UIComponents/Badge";
import SalesList from "src/components/Dashboard/Views/Sales/SalesList";
import Card from "src/components/UIComponents/Cards/Card";

const WITHHELD_SALES_CUTOFF_DATE = '2024-06-01'; // This is set to not return too many historic withheld sales as there are far too many to handle. Cutoff of 6 months prior to this implementation was chosen

export default {
  components: {
    ChartCard,
    Badge,
    SalesList,
    Card,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      ago: null,
      loadTime: null,
      loading: false,
      salesByDay7DaysChart: {
        data: null,
        meta: {
          total: null,
          count: null,
        },
      },
      salesByDay7Days: [],
      withheldSales: [],
      withheldSalesTotalCount: 0,
      withheldSalesLoading: false,
      showWithheldDisbursementsModal: false,
      balance: null,
      lastPayout: null,
      currentDaySales: null,
      terminals: [],
      incidents: [],
    };
  },
  computed: {
    submerchant: function () {
      return this.$store.state.submerchant;
    },
    submerchantUser: function () {
      return this.$store.state.submerchantUser;
    },
    showSubmerchantStatistics: function () {
      const hideForViewersAndManagers = [
        "sub_qj0nkhn9axr4", // Radar Taxis
        "sub_doqgnkhq97ql5", // Fleet Cars
        "sub_2p4xpbmkumg4bw2", // Value Cars Basildon
        "sub_125ikk6w45mp3", // Central Taxis (North East) Ltd
        "sub_ef6608mkupcy76l", // Gatwick Cars
        "sub_4g35qygml8k1mdiu", // Bens Private Hire
        // "sub_177f4vkleir6uan", // Aqua Data Services - driver collections
        "sub_5vqszwimld387nf2", // Aqua Data Services - customer payments
        "sub_11d2lls8x0b6w", // Veezu Ltd Passengers -customer payments (successor to sub_5vqszwimld387nf2)
        "sub_1c32klfuzh9g5", // A1 Taxis Rotherham
        "sub_13qnqlktsnnjp7", // Ecocabs
        "sub_enyvomkxhd8i7h", // A2B Taxis Truro
        "sub_4k35pml9o9a9ox", // Zip
      ];

      const hideForViewers = [];

      if (
        hideForViewersAndManagers.includes(this.submerchant.id) &&
        ["viewer", "manager"].includes(this.submerchantUser.submerchantRole)
      ) {
        return false;
      } else if (
        hideForViewers.includes(this.submerchant.id) &&
        this.submerchantUser.submerchantRole === "viewer"
      ) {
        return false;
      } else if (this.submerchantUser.submerchantRole === "representative") {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    checkSystemStatus() {
      this.axios
        .request({
          url: "https://statuspal.io/api/v1/status_pages/cabcard-services/status",
          method: "get",
          withCredentials: false,
          headers: {},
        })
        .then((response) => {
          this.incidents = response.data.incidents;
        })
        .catch((err) => {
          console.error("Error checking system status: ", err);
        });
    },
    loadTerminals() {
      this.axios
        .get(
          `v1/terminals?currentAllocation[submerchantId]=${this.submerchant.id}`,
          {
            params: {
              limit: 10, // only load 10
            },
          }
        )
        .then((response) => {
          if (response.status !== 200) {
            this.error = response.statusText;
            return;
          }

          if (response.data && response.data.length > 0) {
            this.terminals = response.data;
          }
        })
        .catch((error) => {
          // Request failed.
          this.error = error.response.statusText;
        });
    },
    loadLastPayout() {
      this.axios
        .get(`v1/payouts?submerchantId=${this.submerchant.id}`, {
          params: {
            status: "completed",
            limit: 1,
            sort: "-createdAt",
          },
        })
        .then((response) => {
          if (response.status !== 200) {
            this.error = response.statusText;
            return;
          }

          if (response.data && response.data.length > 0) {
            this.lastPayout = response.data[0];
          }
        })
        .catch((error) => {
          // Request failed.
          this.error = error.response.statusText;
        });
    },
    loadBalance() {
      this.axios
        .get(`v1/balances/?submerchantId=${this.submerchant.id}`)
        .then((response) => {
          if (response.status !== 200) {
            this.error = response.statusText;
            return;
          }

          this.balance = response.data;
        })
        .catch((error) => {
          // Request failed.
          this.error = error.response.statusText;
        });
    },
    loadCurrentDaySales() {
      const startDate = moment().startOf("day");
      const endDate = moment().endOf("day");

      const query = {
        "capturedAt[$gte]": startDate.toISOString(),
        "capturedAt[$lt]": endDate.toISOString(),
        submerchantId: this.submerchant.id,
        aggregate: "amount",
        function: "sum",
      };

      let sum, count, avg;
      // sum
      this.axios
        .get("/v1/sales", { params: query })
        .then((response) => {
          sum = response.data;

          // count
          query.aggregate = true;
          query.function = "count";
          return this.axios.get("/v1/sales", { params: query });
        })
        .then((response) => {
          count = response.data;

          avg = Math.round(sum / count);

          // set the data up
          const currentDaySales = {
            currency: "gbp", // TODO: this should not be hardcoded, should be loaded from submerchant
            count: count,
            amount: sum,
            from: startDate,
            to: endDate,
            average: avg,
          };

          if (count === 0) {
            currentDaySales.average = 0;
          }

          this.currentDaySales = currentDaySales;
          return;
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    },
    loadSalesByDay7Days: function () {
      for (let i = 6; i >= 0; i = i - 1) {
        const startDate = moment().subtract(i, "days").startOf("day");
        const endDate = moment().subtract(i, "days").endOf("day");

        const query = {
          "capturedAt[$gte]": startDate.toISOString(),
          "capturedAt[$lt]": endDate.toISOString(),
          submerchantId: this.submerchant.id,
          aggregate: "amount",
          function: "sum",
        };

        let sum, count;
        this.axios
          .get("/v1/sales", { params: query })
          .then((response) => {
            sum = response.data;

            query.aggregate = true;
            query.function = "count";
            return this.axios.get("/v1/sales", { params: query });
          })
          .then((response) => {
            count = response.data;
            // set the data up
            this.salesByDay7Days.push({
              date: startDate.format("YYYYMMDD"),
              label: `${startDate.format("DD")} ${startDate.format("MMM")}`,
              value: sum,
              count,
            });

            return this.drawSalesByDay7DaysChart();
          })
          .catch((error) => {
            console.error("Error: ", error);
          });
      }
    },
    loadMoreWithheldSales: function () {
      this.withheldSalesLoading = true;

      const limit = 20;
      const offset = this.withheldSales.length;

      this.axios
        .get(`/v1/sales`, {
          params: {
            submerchantId: this.submerchant.id,
            "disbursementWithheld": true,
            "capturedAt[$gte]": WITHHELD_SALES_CUTOFF_DATE,
            limit: limit,
            offset: offset,
            sort: "-capturedAt"
          }
        })
        .then(response => {
          if (response.status !== 200) {
            this.error = response.statusText;
            this.withheldSalesLoading = false;
            return;
          }

          this.withheldSales = [...this.withheldSales, ...response.data];
          this.withheldSalesTotalCount = response.headers['total-count'] || 0;

          this.withheldSalesLoading = false;
        })
        .catch(error => {
          console.error("Error loading withheld sales: ", error);
          this.withheldSalesLoading = false;
        });
    },
    showWithheldSalesModal: function () {
      if (this.withheldSales.length === 0) {
        this.loadMoreWithheldSales();
      }

      // Show modal
      this.showWithheldDisbursementsModal = true;
    },
    drawSalesByDay7DaysChart: function () {
      if (this.salesByDay7Days.length === 7) {
        const total = _.reduce(
          this.salesByDay7Days,
          (memo, item) => {
            return memo + item.value;
          },
          0
        );

        const count = _.reduce(
          this.salesByDay7Days,
          (memo, item) => {
            return memo + item.count;
          },
          0
        );

        const salesByDay7Days = _.sortBy(this.salesByDay7Days, "date");

        this.salesByDay7DaysChart = {
          data: {
            labels: _.map(salesByDay7Days, (item) => {
              return item.label;
            }),
            series: _.map(salesByDay7Days, (item) => {
              return item.value / 100;
            }),
          },
          meta: {
            total: new Intl.NumberFormat("en-GB", {
              style: "currency",
              currency: "GBP",
            }).format(total / 100),
            count: count,
          },
        };
      }
    },
  },
  beforeMount() {
    this.loading = true;

    this.loadSalesByDay7Days();
  },
  mounted() {
    this.loadCurrentDaySales();
    this.loadMoreWithheldSales();

    if (this.submerchantUser.submerchantRole !== "representative") {
      this.loadBalance();
      this.loadLastPayout();
      this.loadTerminals();
    }

    this.checkSystemStatus();
  },
  created() {
    this.loadTime = new Date();
    setInterval(() => {
      this.ago = moment(this.loadTime).toISOString();
    }, 1000);
  },
};
</script>
<style></style>
